.main-background{
  background-color: #F7F9FA;
  display: flex;
  min-height: 100vh;
}



@media screen
and (min-width : 375px)
and (max-width : 1024px){

  .main-background{display:block;}

}
