@font-face {
    font-family: 'Montserrat-Black';
    src: url('https://cdn.jsdelivr.net/gh/yourshansel/neede@88785ccf2cdcabaf746a0f3b9b7586a3f9295580/src/fonts/Montserrat-Black.ttf');
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url('https://cdn.jsdelivr.net/gh/yourshansel/neede@88785ccf2cdcabaf746a0f3b9b7586a3f9295580/src/fonts/Montserrat-Bold.ttf');
}

@font-face {
    font-family: 'Montserrat-Light';
    src: url('https://cdn.jsdelivr.net/gh/yourshansel/neede@88785ccf2cdcabaf746a0f3b9b7586a3f9295580/src/fonts/Montserrat-Light.ttf');
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: url('https://cdn.jsdelivr.net/gh/yourshansel/neede@88785ccf2cdcabaf746a0f3b9b7586a3f9295580/src/fonts/Montserrat-Medium.ttf')
}

@font-face {
    font-family: 'Montserrat';
    src: url('https://cdn.jsdelivr.net/gh/yourshansel/neede@88785ccf2cdcabaf746a0f3b9b7586a3f9295580/src/fonts/Montserrat-Regular.ttf')
}
