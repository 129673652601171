@import 'fonts.css';

.main-array{
  display: flex;
}


.array-component{
  margin-top: 32px;
  flex-wrap: wrap;
  display: flex;
  margin-left: 56px;
  margin-right: 56px;
}

.wrapper {
  display: flex;
}

/* Search Bar */
.search-input {
  padding-left: 72px;
  height: 80px;
  position: relative;
  animation: slide-up 0.4s ease-out forwards;

}

.search-input::before {
  display: block;
  position: relative;
  z-index: 3;
  font-size: 14px;
  top: 12px;
  left: 16px;
  line-height: 32px;

}

.search-input > input {
  width: 320px;
  height: 72px;
  font-family: Montserrat-Medium;
  color: #818C9F;
  font-size: 14px;
  border: none;
  line-height: 22px;
  padding: 5px 10px 5px 25px;
  height: 48px;
  position: relative;
  border-radius: 8px;
}

.search-input > input:focus {
  border-radius: 8px;
  outline: none;
  box-shadow: 0px 2px 24px rgb(102,118,222,0.1);

}
/* Navigation Styles */

.nav{
  width: 200px;
  background-color: #FFF;
  height: 100vh;
  position: sticky;
  top: 0px;
  box-shadow: 1px 0px 16px rgb(102,118,222,0.1);
}

.nav-wrapper {
  overflow: hidden;
}

.nav-content {
  height: 82vh;
  overflow-x: hidden;
  width: 220px;
  overflow-y: scroll;
  border-bottom:1px solid rgb(102,118,222,0.1);
  padding-top: 5%;
}

.nav-header {
  font-family: Montserrat-Bold;
  color: #212121;
  font-size: 16px;
  margin: 64px 72px 16px 40px;
}

.nav-button{
  display: flex;
  width: 160px;
  height: 16px;
  font-family: Montserrat-Medium;
  font-size: 12px;
  color: #818C9F;
  margin-left: 40px;
  padding-top: 4%;
  padding-bottom: 4%;
  margin-right: 72px;
  cursor: pointer;
  fill: #818C9F;
  text-decoration: none;
}


.nav-button:hover{
  color: #5156E5;
  font-family: Montserrat-Medium;
  fill: #5156E5;
}

.spacer{
  margin-right: 8px;
}

.nav-copyright {
  font-family: Montserrat;
  color: #818C9F;
  font-size: 10px;
  padding-top: 8px;
}

.nav-footer{
  height: 18vh;
  position: fixed;
  margin-left: 40px;
  padding-top: 16px;
}

.nav-footer-block{
  display: block;
}

.nav-footer-item{
  font-family: Montserrat-Medium;
  width: 64px;
  padding-top: 6px;
  padding-bottom: 6px;
  text-decoration: none;
  font-size: 12px;
  color: #818C9F;
  display: inline-block;
}

.nav-footer-item:hover{
  color: #5156E5;
  cursor: pointer;
}

.nav-menu{
  display: none;
}


/* Text Styles */

.header{
  color: #212121;
  font-family: Montserrat-Bold;
  font-size: 32px;
  margin-top: 64px;
  margin-bottom: 8px;
  margin-left: 72px;
  animation: appear 0.4s ease-out forwards;
}

.description{
  color: #818C9F;
  font-family: Montserrat;
  font-size: 14px;
  margin-left: 72px;
  margin-bottom: 24px;
  animation: appear 0.4s ease-out forwards;
}

.about-header {
  color: #212121;
  font-family: Montserrat-Bold;
  font-size: 14px;
  margin-top: 32px;
  margin-bottom: 8px;
  margin-left: 72px;
  animation: appear 0.4s ease-out forwards;
}

.about-description{
  color: #818C9F;
  font-family: Montserrat;
  font-size: 14px;
  margin-left: 72px;
  margin-right: 120px;
  margin-bottom: 16px;
  max-width: 640px;
  text-align: left;
  line-height: 1.6;
  animation: appear 0.4s ease-out forwards;
}

.about-link {
  color: #818C9F;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 1.6;

}

.about-spacer {
  height: 40px;
}


/* Mobile Styles */

.mobile-header{
  display:none;
  width: 100%;
}

.mobile-container{
  width: auto;
  display: flex;
  justify-content: space-between;
}

.mobile-menu{
  width: 16px;
  height: 16px;
  fill: #212121;
  margin: 40px 24px;
}

.mobile-menu-off{
  width: 16px;
  height: 16px;
  fill: #212121;
  margin: 40px 24px;
}

.mobile-menu:hover{
  fill: #5156E5;
  cursor: pointer;
}

.neede-logo{
  width: 40px;
  height: 40px;
  margin-top: 24px;
  fill: #212121;
  margin-bottom: 24px;
}


.nav-on{
  z-index: 3;
  position: fixed;
  display: block;
  width: 200px;
  background-color: #fff;
  height: 100vh;
  top: 0px;
  box-shadow: 1px 0px 16px rgb(102,118,222,0.1);
  animation: nav-on 0.25s ease-out forwards;
}


.mobile-overlay-off{
  z-index: 2;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
}

.mobile-overlay{
  z-index: 2;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgb(33,33,33,0.8);
}

.email-wrapper{
  margin-left: 72px;
}

.email-form {
  width: 280px;
  height: 24px;
  font-size: 14px;
  margin-right: 8px;
  padding: 12px 16px 12px 16px;
  background-color: #FFF;
  color: #818C9F;
  border-radius: 4px;
  box-shadow: 1px 0px 16px rgb(102,118,222,0.1);
  border: none;
  font-family: "Montserrat";
  outline: none;
}


.email-form:hover {
  box-shadow: 0px 2px 32px rgb(102,118,222,0.3);

}


.email-button {
  width: auto;
  height: 48px;
  font-size: 14px;
  margin: 8px;
  padding: 12px 16px;
  background-color: #5156E5;
  border: none;
  box-shadow: 1px 0px 16px rgb(102,118,222,0.1);
  border-radius: 4px;
  color: #FFF;
  font-family: "Montserrat";
  outline:none;
}

.email-button:hover {
  cursor: pointer;
  box-shadow: 0px 2px 32px rgb(102,118,222,0.3);

}

.email-success{
  padding-top: 8px;
  color: #5156E5;
  font-size: 14px;
  font-family:  "Montserrat-Medium";
}

.email-error{
  padding-top: 8px;
  color: #E45050;
  font-size: 14px;
  font-family: "Montserrat-Medium";
}


/* Helper */

@keyframes slide-up {
    0% {
       transform: translateY(8%);
       opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }

    }


@keyframes nav-on {
    0% {
       transform: translateX(-100%);
       opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }

    }

@keyframes appear {
    0% {
       opacity: 0;
    }
    100% {
        opacity: 1;
    }

    }


/* Media Queries */


@media screen
and (min-width : 375px)
and (max-width : 1024px){

  .nav {display:none;}
  .mobile-header{display:block;}
  .header {text-align: center; margin-left: 24px; margin-right: 24px; margin-top: 16px;}
  .description {text-align: center; margin-left: 48px; margin-right: 48px; margin-bottom: 24px;}
  .array-component{justify-content: space-around; margin: auto;}
  .nav-button{padding-top: 8px; padding-bottom: 8px;}
  .about-header {text-align: center; margin-left: 48px; margin-right: 48px; margin-top: 40px; margin-bottom: 16px;}
  .about-description {text-align: center; margin-left: auto; margin-right: auto; margin-bottom: 24px; line-height: 2; padding-left: 24px; padding-right: 24px;}
  .email-wrapper {text-align: center; margin-left: 48px; margin-right: 48px; margin-top: 16px; margin-bottom: 16px;}
  .email-form {margin-bottom: 8px;}
  .search-input {text-align: center; padding-left: 0px; height: 120px;}
}

.carbon-ad-wrapper{
  display: block;
  animation: slide-up 0.4s ease-out forwards;
  height: auto;
  width: 320px;
  background-color: #FFF;
  border-radius: 12px;
  box-shadow: 0px 2px 8px rgb(81,86,229, 0.1);
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 64px;
}

.carbon-ad-wrapper:hover {
  opacity: 1;
  box-shadow: 0px 2px 32px rgb(81,86,229, 0.1);
  position: relative;
  top: -5px;


}

#carbonads {
  margin: auto;
  --width: 320px;
  --font-size: 12px;
  color: #FFF;
  font-family: Montserrat;
  display: block;
  overflow: hidden;

  max-width: var(--width);
  text-align: center;
  font-size: var(--font-size);
  line-height: 1.5;
}

#carbonads a {
  color: #818C9F;
  text-decoration: none;
}

#carbonads a:hover {
  text-decoration: underline;
}

#carbonads span {
  position: relative;
  display: block;
  overflow: hidden;
}

.carbon-img {
  padding: 0px 0px 24px;
  display: block;
  max-width: var(--width);
  line-height: 1;
}

.carbon-img img {
  border-radius: 4px;
  display: block;
  margin: 0 auto;
  max-width: var(--width) !important;
  width: 130px;
  height: auto;
}

.carbon-text {
  display: block;
  padding: 0px 32px 40px;
}

.carbon-poweredby {
  bottom: 0;
  display: block;
  padding: 20px;
  text-transform: uppercase;
  letter-spacing: .5px;
  font-weight: 600;
  font-size: 8px;
  line-height: 0;
}

.carbon-ad-tag{
  font-family: Montserrat;
  font-size: 14px;
  text-align: center;
  padding: 16px;
  color: #818C9F;
}


@media only screen and (min-width: 375px) and (max-width: 720px) {
  #carbonads {
    float: none;
    margin: auto;
    max-width: 320px;
  }
  #carbonads span {
    position: relative;
  }
  #carbonads > span {
    max-width: none;
  }
  .carbon-img {
    padding: 0px;
  }

  .carbon-img img {
    max-width: 130px !important;
  }
  .carbon-text {
    margin-bottom: 8px;
    padding: 8px 40px;
    text-align: center;
  }

  .carbon-poweredby {
    left: 130px;
    bottom: 0;
    display: block;
    width: 100%;
  }
  .carbon-ad-wrapper{
    height: auto;
    margin-bottom: 24px;
  }
}
